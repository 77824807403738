import LocalStore from 'devextreme/data/local_store';
import DataSource from 'devextreme/data/data_source';

import api from '@/utils/api.service'
import service from '@/utils/data.service'
// import api from '@/utils/api.service'
// import service from '@/utils/data.service'
// const states = [
//     { id: 1, state: 'Alabama', capital: 'Montgomery' },
//     { id: 2, state: 'Alaska', capital: 'Juneau' },
//     { id: 3, state: 'Arizona', capital: 'Phoenix' },
//     // ...
// ];
 
//const softwaresData = service.fetcher(api.apiUrl + 'softwares', "GET").data;    

// const getData = async() => {
    
// }

// const store = new LocalStore({
//     key: 'id',
//     // data: states,
//     name: 'myLocalData',
//     // Other LocalStore properties go here
// });
 
let store;
let softwaresDataSource;
let severitiesDataSource;
let severitiesDataStore;
// // ===== or inside the DataSource =====
// const softwaresDataSource = new DataSource({
//     store: new LocalStore({
//         key: 'id',
//        // data: softwaresData,
//         name: 'softwares',
//         // Other LocalStore properties go here
//     }),
//     // Other DataSource properties go here
// });
 
// dataSource: new CustomStore({  
//     load: function(loadOptions) {  
//         var local = new LocalStore({  
//             immediate: true,  
//             //                  
//         }),  
//         result;  
//         local.load(loadOptions).done(function(data) {  
//              result = data;  
//         });  
//         if (!result.length) {  
//             var odata = new ODataStore({...});  
//             result = $.Deferred();  
//             odata.load(loadOptions).done(function(data) {  
//                 for (var i = 0; i < data.length; i++) {  
//                     local.insert(data[i]);  
//                 }  
//                 result.resolve(data);  
//             });  
//         }  
//         return result;  
//     }  
// });  

export default {
    store,
    softwaresDataSource,
    severitiesDataSource,
    severitiesDataStore,

    createLocalStore(name, key, data) {
        this.store = new LocalStore({
            key: key,
            data: data,
            name: name,
            // Other LocalStore properties go here
        });
    },

    createSoftwaresDataSource(data) {
        this.softwaresDataSource = new DataSource({
                store: new LocalStore({
                    key: 'id',
                    data: data,
                    name: 'softwares',
                    // Other LocalStore properties go here
                }),
                // Other DataSource properties go here
            });
    },

    async createSeveritiesDataSource() {
        if (!severitiesDataStore) {
            console.log('Create Severities Data Store');
            const severities = await service.fetcher(api.apiUrl + 'severities', "GET");
            //localStore.createSoftwaresDataSource(severities);

            severitiesDataStore = new LocalStore({
                key: 'id',
                data: severities,
                name: 'severities',
                // Other LocalStore properties go here
            });
        }

        if (!severitiesDataSource) {
            console.log('Create Severities Data Source');

            severitiesDataSource = new DataSource({
                store: severitiesDataStore
                // Other DataSource properties go here
            });
        }
        console.log('Return Severities Data Source');

        return severitiesDataSource;
    },    

    getSeveritiesDataSource(){
        if (!this.severitiesDataSource) {
            this.severitiesDataSource = new DataSource({
                store: severitiesDataStore
                // Other DataSource properties go here
            });
        }
        return this.severitiesDataSource;
    },

    createDataSource(name, key){       
        return new DataSource({
            store: new LocalStore({
                key: key,
                // data: states,
                name: name,
                // Other LocalStore properties go here
            }),
            // Other DataSource properties go here
        });
    },
    createDataStore(name, key){       
        return new LocalStore({
            key: key,
            // data: states,
            name: name,
            // Other LocalStore properties go here
        });
    }       
}