<template>
  <drop-down-button
    :items="props.items"
    :visible="props.visible"
    icon="overflow"
    :show-arrow-icon="false"
    styling-mode="text"
    :drop-down-options="{ width: 'auto' }"
  />
</template>
  
<script setup>
import DropDownButton from 'devextreme-vue/drop-down-button';
import { defineProps } from 'vue';

const props = defineProps([
  "items",
  "visible"
]);

console.log("Card Menu Props - Visible" + props.visible);
// console.log(props.visible);
</script>
<style lang="scss">
.dx-dropdownbutton-content {
  .dx-list-item {
    display: block;
  }
}
</style>
  