<template>
  <dx-scroll-view class="view-wrapper-scroll">
    <div class="view-wrapper">

      <dx-toolbar class="details-toolbar">
        <dx-toolbar-item
          location="before"
          locate-in-menu="auto"
          widget="dxButton"
          show-text="inMenu"
          :options="backOptions"
        />
        <dx-toolbar-item
          location="before"
          :text="vehicle.subject"
        />
        <dx-toolbar-item
          location="after"
          locate-in-menu="auto"
        >
          <dx-drop-down-button
            text="Actions"
            styling-mode="contained"
          >
            <dx-drop-down-item text="Duplicate" />
            <dx-drop-down-item text="Close" />
            <dx-drop-down-item text="Delete" />
          </dx-drop-down-button>
        </dx-toolbar-item>

        <dx-toolbar-item
          location="after"
          locate-in-menu="auto"
          widget="dxButton"
          show-text="inMenu"
          :options="attachOptions"
        />

        <dx-toolbar-item
          location="after"
          locate-in-menu="auto"
          widget="dxButton"
          show-text="inMenu"
          :options="refreshOptions"
        />
      </dx-toolbar>

      <div class="panels">
        <div class="left">
          <form-panel
            v-if="showVehicleInfo"
            :entity="vehicle"
            :vehicle="vehicle"
            :editors='[
              year.defaults,
              

              {
                "data-field":"make", 
                "editor-type":"dxSelectBox",
                "label":"Make",
                "hover-state-enabled":"false",
                "editorOptions":{
                  "showClearButton":"true",       
                  "dataSource": getLookupData("makes","id"),
                  "value": vehicle.make,
                  "searchEnabled":"true",
                  "displayExpr":"name",
                  "valueExpr":"id"
                }
              },
              {
                "data-field":"model", 
                "editor-type":"dxTextBox",
                "label":"Model",
                "editorOptions":{"showClearButton":"true"}            
              },
              {
                "data-field":"submodel", 
                "editor-type":"dxTextBox",
                "label":"Submodel",
                "editorOptions":{"showClearButton":"true"}            
              },
              {
                "data-field":"installTypeId", 
                "editor-type":"dxSelectBox",
                "label":"Install Type",
                "hover-state-enabled":"false",
                "editorOptions":{
                  "showClearButton":"true",
                  "dataSource": getLookupData("installTypes","id"),
                  "value": vehicle.installTypeId,
                  "searchEnabled":"true",
                  "displayExpr":"name",
                  "valueExpr":"id"
                }
              },
              {
                "data-field":"cab", "label":"Cab", "editor-type":"dxTextBox", "editorOptions":{"showClearButton":"true"}            
              },
              {
                "data-field":"wheelbase", 
                "editor-type":"dxTextBox",
                "editorOptions":{"showClearButton":"true"}            
              },
              {
                "data-field":"cabaxle", 
                "editor-type":"dxTextBox",
                "label":"Cabaxle",
                "editorOptions":{"showClearButton":"true"}            
              },  
              {
                "data-field":"rearWheelTypeId", 
                "editor-type":"dxSelectBox",
                "label":"Rear Wheel Type",
                "hover-state-enabled":"false",
                "editorOptions":{
                  "showClearButton":"true",        
                  "dataSource": getLookupData("rearWheelTypes","id"),
                  "value": vehicle.rearWheelTypeId,
                  "searchEnabled":"true",
                  "displayExpr":"name",
                  "valueExpr":"id"
                }
              },                                                      
            ]'

            @onSave="okClicked"
            @onEdit="editClicked"
            @onCancel="cancelClicked"
          />       
        </div>

        <div class="right">
          <div class="dx-card details-card">
            <dx-tab-panel
              :show-nav-buttons="true"
              :defer-rendering="false"
            >
              <dx-item 
                title="History"
                :badge="activityBadge"
              >
                <card-activities
                  :items="taskData?.activities"
                  :is-loading="isLoading"
                  :show-by="true"
                />
              </dx-item>
              <dx-item title="Notes">
                <!-- <form-panel
                  v-if="showVehicleInfo"
                  :vehicle="vehicle"
                  :companies="companies"
                  :users="users"
                  :severities="severities"
                  :softwares="softwares"
                  :statuses="statuses"
                /> -->
                <DxButton
                  text="OK"
                  @click="okClicked"
                />
              </dx-item>
              <dx-item
                title="Messages"
              >
                <div>test</div>
              </dx-item>
            </dx-tab-panel>
          </div>
        </div>
   </div>
  </div>
</dx-scroll-view>
</template>
  
<script setup>
import "devextreme/data/odata/store";
 import service from '../utils/data.service';
import 'whatwg-fetch';
import { ref, reactive, computed, onMounted } from 'vue';
import { DxTabPanel, DxItem } from 'devextreme-vue/tab-panel';
import FormPanel from '@/components/vehicle-form-panel.vue';
import { DxScrollView } from 'devextreme-vue/scroll-view';
import { defineProps } from 'vue';
 import CardActivities from '@/components/card-activities.vue';
 import {
  DxToolbar,
  DxItem as DxToolbarItem,
} from 'devextreme-vue/toolbar'; 
import { DxDropDownButton, DxItem as DxDropDownItem } from 'devextreme-vue/drop-down-button';
import DxButton from 'devextreme-vue/button';
import notify from 'devextreme/ui/notify';
import { useRoute } from 'vue-router'
import router from "@/router";
import local from '@/stores/local';

const route = useRoute();
const id = route.params.id;

console.log('ID FROM ROUTE:' + id);

const props = defineProps(['columns', 'endpoint', 'header']);
console.log(props);

const customDataSource = service.createDataSource(props.endpoint, props.id);

console.log(customDataSource);


function getLookupData(endpoint, id){
  if (props.useLocal){
    return local.createDataSource(endpoint, id)
  }
  else{
    return service.createDataSource(endpoint, id)
  }
}

const dsVehicles = service.createDataSource('vehicles', 'id');
const vehicle = ref({});// dsVehicles.byKey(id));
let showVehicleInfo = true;
const isLoading = ref(false);
const isEditing = ref(false);

// const defaults = {
//   "editor-type":"dxTextBox",
//   "editor-options":{"showClearButton":"true", "readOnly": "true"}         
// };

const test = reactive();
console.log(test);

let year = ref({
  defaults:[{
    "data-field":"model", 
    "editor-type":"dxTextBox",
    "label":"Model",
    
    "editor-options":{"showClearButton":"true", "readOnly": "!isEditing.value"}            
  },
  {
                "data-field":"installTypeId", 
                "editor-type":"dxSelectBox",
                "label":"Install Type",
                "hover-state-enabled":"false",
                "editorOptions":{
                  "showClearButton":"true",
                  "dataSource": getLookupData("installTypes","id"),
                  "value": vehicle.value.installTypeId,
                  "searchEnabled":"true",
                  "displayExpr":"name",
                  "valueExpr":"id"
                }
              }] 
});



const taskData = ref({
  id: 1,
  text: "text",
  description: "desc",
  company: "company",
  priority: "priority",
  startDate: "startdate",
  dueDate: "duedate",
  owner: "owner",
  status: "status",
  activities: [{    
      name: "Changed Subject",
      date: "10/19/22",
      manager: "Austin Shaw",
    },
    {
      name: "Assigned to Austin Shaw",
      date: "10/14/22",
      manager: "John Maschman",
    },
    {
      name: "Assigned to John Maschman",
      date: "10/12/22",
      manager: "Tom Goduto",
    },
    {
      name: "Created",
      date: "10/12/22",
      manager: "Austin Shaw",
    }    
  ],
  notes: "notes",
  messages: "messages",
  parentId: 2,
  progress: 3,
});



const activityBadge = computed(() => {
  const length = taskData.value ? taskData.value.activities?.length : 0;
  return (length >= 0) ? `${length}` : '...';
});

const okClicked = () => {
    dsVehicles.update(vehicle.value.id, vehicle.value);

    dsVehicles.push([{
      type: 'update',
      key: vehicle.value.id,
      data: vehicle.value
  }]);
}

const editClicked = () => {
  isEditing.value = true;
}

const cancelClicked = () => {
  isEditing.value = false;
}

const backClicked = () => {
    notify('The back button was clicked');
    router.push({ path: `/vehicles` });
}

const refresh = async() => {
  vehicle.value = await dsVehicles.byKey(id);
};

const attachOptions = {
  text: 'Attach',
  icon: 'attach',
  stylingMode: 'text',
};

const refreshOptions = {
  text: 'Refresh',
  icon: 'refresh',
  onClick: refresh,
  stylingMode: 'text',
};

const backOptions = {
  text: 'Back',
  icon: 'arrowleft',
  onClick: backClicked,
  stylingMode: 'text',
};

onMounted(() => {
  refresh();//loadData();
});
</script>

<style scoped lang="scss">
@use "@/variables" as *;

$left-panel-width: 400px;

.dx-toolbar {
  margin-bottom: calc(#{$toolbar-margin-bottom} / 2);

  :deep(.dx-toolbar-label > div) {
    @include header();
  }
}

// .details-toolbar{
//   background-color: #f2f2f2;
// }


.view-wrapper {
  padding: $content-padding $content-padding 0 $content-padding;

  .panels {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;

    .left {
      flex: 1 600px;
      margin-top: 8px;
    }

    .right {
      flex: 1 calc(100% - 600px - 20px);
      margin-top: 8px;
      min-width: 340px;
    }
  }
}
</style>