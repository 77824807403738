<template>
  <dx-load-panel
    :visible="props.isLoading"
    :show-pane="false"
    :width="props.width"
    :container="props.containerSelector"
    :position="{ of: props.containerSelector }"
  />
  <slot v-if="props.showContent" />
</template>

<script setup>
import DxLoadPanel from 'devextreme-vue/load-panel';
import { defineProps } from 'vue';
//const props = defineProps(['columns', 'endpoint', 'id']);

const props = defineProps([
  "isLoading",
  "containerSelector",
  "width",
  "showContent"
]);

console.log('props');
console.log(props.IsLoading);

</script>

<style scoped lang="scss">

</style>
