<template>
  <div

    class="severity severity-indicator"
  >
    <!-- drop down fields -- IsField = FALSE -->
    <span 
      v-if="!props.isField"
      :style="{ color: props.color}">
    {{ props.data.name }}
    </span>

    <!-- single text box field -- IsField = TRUE -->
    <dx-text-box
      v-if="props.isField"
   
      class="severity-indicator"
      :input-attr="{ class: 'severity-input severity-editor-input' }"      

      :read-only="true"
      :style="{ color: '#000000'}"
      :value="props.value && props.value.name"
    />
  </div>
</template>

<script setup>
import { DxTextBox } from 'devextreme-vue/text-box';
import { ref } from 'vue';
import { defineProps } from 'vue';

const props = defineProps([
    'value',
    'data',
    'showBar',
    'isField',
    'color'
]);

const color = ref(props.value && props.value.color);

</script>

<style lang="scss">
.severity {
  span {
    font-size: 13px;
  }
}

.severity-indicator{

  &.dx-texteditor {
    .severity-input.severity-editor-input {
      color:  v-bind(color);//#2eb52c;
      font-weight: bold;
    }
  }

  &::before {
    background: v-bind(color);
  }
}
</style>
