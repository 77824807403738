<template>
    
    <div :class="!props.isComponent ? 'view-wrapper' : ''" >
    <!-- <div class="view-wrapper">     -->

    <page-toolbar
      v-if=!props.isComponent
      :header="props.header"
    />

    <slot/>

    <dx-data-grid
      id="grid-container"
      class="dx-card wide-card"
      :data-source="customDataSource"
      key-expr="id"
      height="100%"
      :focused-row-index="0"
      :show-borders="false"
      :column-hiding-enabled="true"

      :focused-row-enabled="true"
      :column-auto-width="true"
      :allow-column-reordering="true"

      @exporting="service.onExporting"
      :on-saving="service.onSaving"
      @selection-changed="onSelectionChanged"
    >
      <dx-scrolling 
        v-if=!props.isComponent
        mode="virtual" 
      />

      <dx-column
        v-for="column in props.columns"
        :key="column.name"
        v-bind="column"
        >
        <dx-lookup
          v-if="column.isLookup === true"
          :data-source="getLookupData(column.dataSource,column.dataSourceId)"
          :value-expr="column.dataSourceId"
          :display-expr="column.dataSourceValue"
        />    
      </dx-column>

      <template #editCellTemplate="{ data }">
        <dx-color-box
          :value="data.value"
          @value-changed="(e) => onColorChange(e.value, data)"
          @selection-changed="data.component.updateDimensions"
        />        
      </template>      
      <template #cellTemplate="{ data }">
        <div
          :style="{ backgroundColor: data.value }"
          class="color-block"
        />
        <!-- <div class="dx-colorbox-color-result-preview" style="background-color: rgb(255, 149, 0);"></div>
        <dx-color-box
          v-if="false"
          :value="data.value"
          :read-only="true"
        />         -->
      </template>

      <template #editPhotoCellTemplate="{ data }">
        <div>
            <img 
              v-if="!previewImage && data.value"
              :src="'data:image/png;base64,' + data.value" 
              class="uploading-image" 
              style="max-width:200px;max-height:100px;width:100%"/>
            <img 
              v-if="previewImage"
              :src="previewImage" 
              class="uploading-image2" 
              style="max-width:200px;max-height:100px;width:100%"/>
            
              <input 
              type="file" 
              accept="image/png" 
              @change="(e) => uploadImage(e, data)"
            >
        </div>
      </template>
      
      <template #photoCellTemplate="{ data }">
        <div>
          <img
            v-if="data.value"
            :src="'data:image/png;base64,' + data.value"
            style="max-width:200px;max-height:20px"
          >
        </div>
      </template>

      <!-- <dx-remote-operations
            :filtering="true"
            :paging="true"
            :sorting="true"
            :summary="true"
            :grouping="true"
            :group-paging="true"
        /> -->
        
      <dx-sorting mode="multiple" />
      <dx-header-filter :visible="true" />
      <dx-selection mode="single"/>
      <dx-summary>
        <dx-group-item
          summary-type="count"
        />
      </dx-summary>

      <dx-grouping :auto-expand-all="false" :context-menu-enabled="true" expand-mode="rowClick"/>
      <dx-group-panel :visible="true" />
      <!-- <dx-group-panel :visible="true" empty-panel-text="My Quotes"/> -->
      <dx-column-chooser :enabled="true" mode="select" :allow-search="true"/>
      <!-- <dx-column-fixing :enabled="true"/> -->
      <!-- <dx-paging :page-size="10" /> -->
      <!-- <dx-pager :show-page-size-selector="true" :show-info="true" /> -->
      <dx-filter-row :visible="true" />
      <dx-search-panel :visible="true" />
      <dx-editing mode="form" :use-icons="true" :allow-updating="true" :allow-adding="true" :allow-deleting="true"/>
      <dx-export :enabled="true" :formats="['pdf']" :allow-export-selected-data="true"/>      

      <dx-toolbar>
        <dx-item name="groupPanel" />
        <dx-item location="after" template="button-template" />
        <dx-item name="addRowButton" show-text="always" />
        <dx-item name="exportButton" />
        <dx-item name="columnChooserButton" />
        <dx-item name="searchPanel" />
      </dx-toolbar>
      <template #button-template>
        <dx-button
          :text="expanded ? 'Collapse All' : 'Expand All'"
          :width="136"
          @click="expanded = !expanded"
        />
      </template>

    </dx-data-grid>
  </div>
</template>

<script setup>
import "devextreme/data/odata/store";
import service from '../utils/data.service';
import local from '../stores/local';
import 'whatwg-fetch';
import { defineProps, defineEmits, ref } from 'vue';
import PageToolbar from "../components/page-toolbar";
import DxColorBox from 'devextreme-vue/color-box';
import DxDataGrid, {
  DxScrolling,
  DxColumn,
  DxSorting,
  DxFilterRow,
  DxLookup,
  DxToolbar,
  DxGroupPanel,
  DxItem,
  DxSummary,
  DxGroupItem,
  // DxPager,
  // DxPaging,
  DxEditing,
  DxHeaderFilter,
  DxGrouping,
  DxSelection,
  DxButton,

  DxExport,
  DxColumnChooser,
  // DxColorBox,
  // DxColumnFixing,
  DxSearchPanel,
  // dxRemoteOperations,
} from "devextreme-vue/data-grid";


const expanded = ref(true);
const previewImage= ref();
const props = defineProps(['columns', 'endpoint', 'id', 'header', 'useLocal', 'isComponent']);
const emit = defineEmits(['onSelectionChanged']);
const customDataSource = service.createDataSource(props.endpoint, props.id);

function getLookupData(endpoint, id){
  if (props.useLocal){
    return local.createDataSource(endpoint, id)
  }
  else{
    return service.createDataSource(endpoint, id)
  }
}


const onSelectionChanged = ({selectedRowsData}) => {
  console.log('push emit');
  previewImage.value = null;
  const data = selectedRowsData[0];
  emit('onSelectionChanged', data);
  //router.push({ path: `/work-order-detail/${data.id}` })
} 

const onColorChange = (value, cellInfo) => {
  cellInfo.setValue(value);
  cellInfo.component.refresh();
};



function uploadImage(e, data){
    

    const image = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onload = e =>{
        previewImage.value = e.target.result;
        console.log(previewImage.value);

        data.setValue(previewImage.value);
        //data.value = previewImage.value;

        console.log(data.value);
    };
}

</script>

<style scoped lang="scss">
@use "@/variables" as *;

@include separator();

.color-block {
  width: 100%;
  height: 20px;
  background-color: #f05b41;
  position: relative;
}
.view-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  .dx-toolbar {
    padding: $toolbar-vertical-padding $content-padding;
  }

  :deep(.dx-toolbar) {
    .toolbar-header {
      @include header();
    }
  }

  .grid, .kanban, .gantt {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    max-height: calc(100% - $toolbar-height - $content-padding * 2);
  }
}
</style>

