import { defineStore } from 'pinia'
import { useLocalStorage } from "@vueuse/core"

// You can name the return value of `defineStore()` anything you want,
// but it's best to use the name of the store and surround it with `use`
// and `Store` (e.g. `useUserStore`, `useCartStore`, `useProductStore`)
// the first argument is a unique id of the store across your application
export const useUserStore = defineStore('user', () => {
  const enterpriseId = useLocalStorage('enterpriseId', null);
  const storeId = useLocalStorage('storeId', null);
  const user = useLocalStorage('user', null);
  const id = useLocalStorage('id', null);
  const username = useLocalStorage('username',null);
  const email = useLocalStorage('email',null);
  const password = useLocalStorage('password',null);
  const firstname = useLocalStorage('firstname',null);
  const lastname = useLocalStorage('lastname',null);
  const phone = useLocalStorage('phone',null);  
  const token = useLocalStorage('token', null);
  const expireDtm = useLocalStorage('expireDtm', null);
  const isAdmin = useLocalStorage('isAdmin', false);
  const isEnterprise = useLocalStorage('isEnterprise',false);

  return { enterpriseId, storeId, user, id, username, email, password, firstname, lastname, phone, token, expireDtm, isAdmin, isEnterprise}  
})