export default [
  {
    text: "Home",
    path: "/home",
    icon: "home"
  }, 
  {
    text: "Quotes",
    icon: "paste",
    items: [
      {
        text: "Search",
        path: "/quotes",
        icon: "find"
      },
      {
        text: "New",
        path: "/profile",
        icon: "add"
      }
    ]
  },
  {
    text: "Store",
    icon: "box",
    hidden: true,
    items: [
      {
        text: "Members",
        path: "/tasks",
        icon: "group"
      }
    ]
  },
  {
    text: "Enterprise",
    icon: "link",
    hidden: true,
    items: [
      {
        text: "Stores",
        path: "/stores",
        icon: "box"
      },  
      {
        text: "Members",
        path: "/members",
        icon: "group"
      },
      {
        text: "Manufacturers",
        path: "/manufacturers",
        icon: "box"
      },      
      {
        text: "Packages",
        path: "/packages",
        icon: "product"
      },
      {
        text: "Parts",
        path: "/parts",
        icon: "toolbox"
      }, 
      {
        text: "Settings",
        path: "/settings",
        icon: "preferences"
      },       
      {
        text: "Vehicles",
        path: "/vehicles",
        icon: "car"
      },        
      {
        text: "Vendors",
        path: "/vendors",
        icon: "box"
      }      
    ]
  }  
];
