
//import useRouter from 'vue-router';
import userService from '@/utils/user.service';
import router from '../router'; 
//const router = useRouter();

export default {
    apiUrl: "https://link-workflow-api.azurewebsites.net/api/",
    //apiUrl: "https://hydra-desk-api.azurewebsites.net/api/",
    //apiUrl : "https://localhost:7250/api/",
    apiDevUrl: "http://localhost:5058/api/",

    handleErrors(response) {
        if (response.status == 401){
           userService.logOut();

           console.log('Handle Errors - 401 - Logout')
           router.push("/login-form");            
        }

        if (!response.ok) {
            console.log('Handle Errors - XXX')
            throw Error("Error: " + response.statusText);
        }
        return response;
    }
}