import service from "@/utils/user.service";
import { createRouter, createWebHashHistory } from "vue-router";

import Home from "./views/home-page";
import Profile from "./views/profile-page";
import Tasks from "./views/tasks-page";
import Stores from "@/views/stores-page";
import Members from "@/views/members-page";
import Quotes from "@/views/quotes-page";
import Vendors from "@/views/vendors-page";
import Vehicles from "@/views/vehicles-page";
import VehicleDetail from "@/views/vehicle-detail-page";
import Manufacturers from "@/views/manufacturers-page";
import Packages from "@/views/packages-page";
import Parts from "@/views/parts-page";
import Settings from "@/views/manufacturers-page";
import defaultLayout from "./layouts/side-nav-outer-toolbar";
import simpleLayout from "./layouts/single-card";

function loadView(view) {
  return () => import (/* webpackChunkName: "login" */ `./views/forms/${view}.vue`)
}

const router = new createRouter({
  routes: [
    {
      path: "/home",
      name: "home",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Home
    },
    {
      path: "/profile",
      name: "profile",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Profile
    },
    {
      path: "/stores",
      name: "stores",
      meta: {
        requiresAuth: true,
        requiresEnt: true,
        layout: defaultLayout
      },
      component: Stores
    },
    {
      path: "/quotes",
      name: "quotes",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Quotes
    },         
    {
      path: "/members",
      name: "members",
      meta: {
        requiresAuth: true,
        requiresEnt: true,
        layout: defaultLayout
      },
      component: Members
    },    
    {
      path: "/vendors",
      name: "vendors",
      meta: {
        requiresAuth: true,
        requiresEnt: true,
        layout: defaultLayout
      },
      component: Vendors
    },  
    {
      path: "/vehicles",
      name: "vehicles",
      meta: {
        requiresAuth: true,
        requiresEnt: true,
        layout: defaultLayout
      },
      component: Vehicles
    },  
    {
      path: "/vehicle-detail/:id",
      name: "vehicle-detail",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: VehicleDetail,
      props: true
    },            
    {
      path: "/manufacturers",
      name: "manufacturers",
      meta: {
        requiresAuth: true,
        requiresEnt: true,
        layout: defaultLayout
      },
      component: Manufacturers
    },     
    {
      path: "/packages",
      name: "packages",
      meta: {
        requiresAuth: true,
        requiresEnt: true,
        layout: defaultLayout
      },
      component: Packages
    }, 
    {
      path: "/parts",
      name: "parts",
      meta: {
        requiresAuth: true,
        requiresEnt: true,
        layout: defaultLayout
      },
      component: Parts
    },     
    {
      path: "/settings",
      name: "settings",
      meta: {
        requiresAuth: true,
        requiresEnt: true,
        layout: defaultLayout
      },
      component: Settings
    },            
    {
      path: "/tasks",
      name: "tasks",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Tasks
    },
    {
      path: "/login-form",
      name: "login-form",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Sign In"
      },
      component: loadView("login-form")
    },
    {
      path: "/reset-password",
      name: "reset-password",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Reset Password",
        description: "Please enter the email address that you used to register, and we will send you a link to reset your password via Email."
      },
      component: loadView("reset-password-form")
    },
    {
      path: "/create-account",
      name: "create-account",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Sign Up"
      },
      component: loadView("create-account-form"),
    },
    {
      path: "/change-password/:recoveryCode",
      name: "change-password",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Change Password"
      },
      component: loadView("change-password-form")
    },
    {
      path: "/",
      redirect: "/home"
    },
    {
      path: "/recovery",
      redirect: "/home"
    },
    {
      path: "/:pathMatch(.*)*",
      redirect: "/home"
    }
  ],
  history: createWebHashHistory()
});

router.beforeEach((to, from, next) => {

  console.log('is Auth check = ' + to.matched.some(record => record.meta.requiresAuth));
  console.log('is Ent check = ' + to.matched.some(record => record.meta.requiresEnt));
  console.log('!!service.loggedIn() = ' + !!service.loggedIn());
  console.log('!!service.isEnterprise() = ' + !!service.isEnterprise());

  if (to.name === "login-form" && service.loggedIn()) {
    console.log('not logged in - redirect to login form')
    next({ name: "home" });
  } 
  else if (to.matched.some(record => record.meta.requiresAuth) && !service.loggedIn()) {
    console.log('requries auth & not authorized - redirect home');
    next({
      name: "login-form",
      query: { redirect: to.fullPath }
    });
  }
  else if (to.matched.some(record => record.meta.requiresEnt) && !service.isEnterprise()) {
    console.log('requires Ent &B not enterprise - redirect home' + service.isEnterprise());
    next({ name: "home" });
  }
  else
  {   
    console.log('next - authorized');
    next();    
  }
  // if (to.matched.some(record => record.meta.requiresEnt)){
  //   console.log('check - requires Ent: true');
  // }

  // console.log('check - is Ent: ' + service.isEnterprise())

  // if (to.matched.some(record => record.meta.requiresEnt) && service.isEnterprise() != true) {
  //   next({ name: "home" });
  // }  else {
  //   next();
  // }
});

export default router;
