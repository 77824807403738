<template>
  <GridPanel 
    :columns='[
      {"data-field":"year"},
      {"data-field":"make"},
      {"data-field":"model"},
      {"data-field":"submodel"},
      {"data-field":"installTypeId"},
      {"data-field":"cab"},
      {"data-field":"wheelbase"},
      {"data-field":"cabaxle"},
      {"data-field":"af"},
      {"data-field":"oal"},
      {"data-field":"box"},
      {"data-field":"framewidth"},
      {"data-field":"rearWheelTypeId"},
      {"data-field":"roof"},
      {"data-field":"overallHeight"},
      {"data-field":"readDoorHeight"},
      {"data-field":"cargoHeight"},
      {"data-field":"sideDoorHeight"},
      {"data-field":"overallLength"},
      {"data-field":"overhangFront"},
      {"data-field":"overhangRear"},
      {"data-field":"cargoOverallLength"},
      {"data-field":"sideDoorWidth"},
      {"data-field":"vehicleWidth"},
      {"data-field":"vehicleWidthShortMirror"},
      {"data-field":"vehicleWidthExtMirror"},
      {"data-field":"widthBetweenWindows"},
      {"data-field":"cargoWidthFloor"},
      {"data-field":"cargoHeight2"},
      {"data-field":"headroom1row"},
      {"data-field":"cargoLengthFloor"},
      {"data-field":"cargoLengthBeltline"},
      {"data-field":"legroom1row"},
      {"data-field":"shoulderroom"},
      {"data-field":"hiproom"},
      {"data-field":"imgfile"},
      {"data-field":"note"},
      {"data-field":"createdDtm", "data-type":"date"},
      ]'
    endpoint="vehicles"
    header="Vehicles"
    id="id"
    :useLocal="false"
    @onSelectionChanged="onSelectionChanged"        
  />
</template>
  
<script setup>
import "devextreme/data/odata/store";
import router from "@/router";
import GridPanel from "../components/grid-panel";

const onSelectionChanged = (selectedRowsData) => {
  console.log("selection changed!")
  console.log(selectedRowsData);
  const data = selectedRowsData;
  router.push({ path: `/vehicle-detail/${data.id}` })
} 


</script>