<template>
    <dx-toolbar class="toolbar-details">
    <dx-toolbar-item location="before">
      <span class="toolbar-header">{{ props.header }}</span>
    </dx-toolbar-item>

    <!-- <dx-toolbar-item
      location="after"
      locate-in-menu="auto"
      css-class="add-grid-row"
    >
      <div>
        <dx-button
          icon="plus"
          text="Add Task"
          type="default"
          styling-mode="contained"
        />
      </div>
    </dx-toolbar-item>
    <dx-toolbar-item
      location="after"
      locate-in-menu="auto"
      widget="dxButton"
      show-text="inMenu"
      :options="refreshOptions"
    />

    <dx-toolbar-item
      location="after"
      locate-in-menu="auto"
      widget="dxButton"
      show-text="inMenu"
      :options="columnChooserOptions"
    />

    <dx-toolbar-item
      location="after"
      locate-in-menu="auto"
    >
      <div>
        <div class="separator" />
      </div>
    </dx-toolbar-item>

    <dx-toolbar-item
      :disabled="!['grid', 'gantt'].includes(activeTabId)"
      location="after"
      locate-in-menu="auto"
      widget="dxButton"
      show-text="inMenu"
      :options="exportToPdfOptions"
    />

    <dx-toolbar-item
      :disabled="!['grid'].includes(activeTabId)"
      location="after"
      locate-in-menu="auto"
      widget="dxButton"
      show-text="inMenu"
      :options="exportToXlsxOptions"
    />
    <dx-toolbar-item
      :disabled="!['grid'].includes(activeTabId)"
      location="after"
      locate-in-menu="auto"
      widget="dxTextBox"
      :options="taskSearchOptions"
    /> -->
  </dx-toolbar>
</template>

<script setup>
import { defineProps } from 'vue';
import {
  DxToolbar,
  DxItem as DxToolbarItem,
} from 'devextreme-vue/toolbar';

const props = defineProps(['header', 'endpoint', 'id']);

console.log(props);

// const refreshOptions = {
//   text: 'Refresh',
//   icon: 'refresh',
//   //onClick: reload,
//   stylingMode: 'text',
// };

// const columnChooserOptions = {
//   text: 'Column Chooser',
//   icon: 'columnchooser',
//   //onClick: chooseColumnDataGrid,
//   stylingMode: 'text',
// };

// const exportToPdfOptions = {
//   text: 'Export to PDF',
//   icon: 'exportpdf',
//   //onClick: exportToPdf,
//   stylingMode: 'text',
// };

// const exportToXlsxOptions = {
//   text: 'Export to Exel',
//   icon: 'exportxlsx',
//   //onClick: exportToXlsx,
//   stylingMode: 'text',
// };

// const taskSearchOptions = {
//   placeholder: 'Task Search',
//   mode: 'search',
//   //onInput: searchDataGrid,
// };

</script>
