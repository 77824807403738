<!-- eslint-disable vue/no-mutating-props -->
<template>
    <dx-toolbar v-if="!isCreateMode">
      <dx-toolbar-item location="before">
        <span class="dx-form-group-caption">Details</span>
      </dx-toolbar-item>
      <dx-toolbar-item
        location="after"
        locate-in-menu="after"
        :visible="!isEditing"
      >
        <dx-button
          text="Edit"
          icon="edit"
          styling-mode="outlined"
          type="default"
          @click="editClick"
        />
      </dx-toolbar-item>
      <dx-toolbar-item
        location="after"
        locate-in-menu="after"
        :visible="isEditing"
      >
        <dx-button
          text="Save"
          styling-mode="outlined"
          type="default"
          @click="validateClick"
        />
      </dx-toolbar-item>
      <dx-toolbar-item
        location="after"
        locate-in-menu="after"
        :visible="isEditing"
      >
        <dx-button
          text="Cancel"
          @click="cancelClick"
          styling-mode="text"
        />
      </dx-toolbar-item>
    </dx-toolbar>  
  <dx-form
    id="form"
    label-mode="static"
    styling-mode="filled"
    :form-data="props.vehicle"
    validation-group="formGroup"
    @click="formClick"
  >
    <dx-group-item>
      <dx-group-item>
        <dx-col-count-by-screen :xs="2" :sm="2" :md="2" :lg="2" />        

        <dx-item 
          data-field="year" 
          editor-type="dxTextBox"
          @onClick="formClick"
          :editor-options="{
            showClearButton:'true',
            readOnly: !isEditing,
            value: props.vehicle.year,
          }">
          <dx-required-rule v-if=isEditing message="Year is required"/>
          <dx-label text="Year"/>
        </dx-item >

        <dx-item 
          data-field="make" 
          editor-type="dxSelectBox"
          :editor-options="{...{showClearButton:'true'}, ...{
            readOnly: !isEditing,
            dataSource: dsLocalMakes,
            value: props.vehicle.make,
            searchEnabled:'true',
            displayExpr:'name',
            valueExpr:'id'
          }}">
          <dx-required-rule v-if=isEditing message="Make is required"/>
          <dx-label text="Make"/>
        </dx-item >

        <dx-item 
          data-field="model" 
          editor-type="dxTextBox"
          @onClick="formClick"
          :editor-options="{
            showClearButton:'true',
            readOnly: !isEditing,
            value: props.vehicle.model,
          }">
          <dx-required-rule v-if=isEditing message="Model is required"/>
          <dx-label text="Model"/>
        </dx-item >

        <dx-item 
          data-field="submodel" 
          editor-type="dxTextBox"
          @onClick="formClick"
          :editor-options="{
            showClearButton:'true',
            readOnly: !isEditing,
            value: props.vehicle.submodel,
          }">
          <dx-required-rule v-if=isEditing message="Submodel is required"/>
          <dx-label text="Submodel"/>
        </dx-item >        

        <dx-item
          data-field="installTypeId"
          editor-type="dxSelectBox"
          :hover-state-enabled="false"
          :editor-options="{  
            showClearButton:'true', 
            readOnly: !isEditing,            
            dataSource: dsLocalInstallTypes,
            value: props.vehicle.installTypeId,
            searchEnabled:'true',
            displayExpr:'name',
            valueExpr:'id'
          }">
          <dx-label text="Install Type"/>
        </dx-item>

        <dx-item 
          data-field="cab" 
          editor-type="dxTextBox"
          @onClick="formClick"
          :editor-options="{
            showClearButton:'true',
            readOnly: !isEditing,
            value: props.vehicle.year,
          }">
          <dx-required-rule v-if=isEditing message="Year is required"/>
          <dx-label text="Year"/>
        </dx-item >

        <dx-item
          data-field="rearWheelTypeId"
          editor-type="dxSelectBox"
          :hover-state-enabled="false"
          :editor-options="{  
            showClearButton:'true', 
            readOnly: !isEditing,            
            dataSource: dsLocalRearWheelTypes,
            value: props.vehicle.rearWheelTypeId,
            searchEnabled:'true',
            displayExpr:'name',
            valueExpr:'id'
          }">
          <dx-required-rule v-if=isEditing message="Rear Wheel Type is required"/>          
          <dx-label text="Rear Wheel Type"/>
        </dx-item>

        <dx-item
          data-field="assignedDtm"
          editor-type="dxDateBox"
          :editor-options="{  
            readOnly: !isEditing,           
            value: props.vehicle.assignedDtm
          }">
          <dx-label text="Assigned Date"/>
        </dx-item>

        <dx-item
          data-field="openedDtm"
          editor-type="dxDateBox"
          :editor-options="{  
            readOnly: !isEditing,           
            value: props.vehicle.openedDtm
          }">
          <dx-label text="Opened Date"/>
        </dx-item>

        <dx-item
          data-field="closedDtm"
          editor-type="dxDateBox"
          :editor-options="{  
            readOnly: !isEditing,           
            value: props.vehicle.closedDtm
          }">
          <dx-label text="Closed Date"/>
        </dx-item>

        <dx-item
          data-field="dueDtm"
          editor-type="dxDateBox"
          :editor-options="{  
            readOnly: !isEditing,           
            value: props.vehicle.dueDtm
          }">
          <dx-label text="Due Date"/>
        </dx-item>

        <dx-item data-field="makeId">
          <dx-select-box
            :data-source="dsLocalMakes"
            label-mode="static"
            label="Severity"
            styling-mode="filled"   
            :read-only=!isEditing
            v-model="props.vehicle.severityId"
            :value="props.vehicle.severityId"
            display-expr="name"
            value-expr="id"
              
            field-template="field"
            item-template="item"
          >
            <template #field="{ data }">
              <div class="task-editor-field">
                <!-- <status-indicator
                  :is-field="true"
                  :show-bar="true"
                  :value="data"
                /> -->

                <status-indicator
                  :is-field="true"
                  :show-bar="true"
                  :value="data"
                  :data="data"
                  :color="data"
                />                
              </div>
            </template>
            <template #item="{ data }">
              <status-indicator
                :show-bar="true"
                :value="data.name"
                :data="data"
                :color="data.color"
              />
            </template>
          </dx-select-box>
        </dx-item>

        <dx-simple-item
          :col-span="2"
          data-field="description"
          editor-type="dxTextArea"
          value: props.vehicle.description,
          :editor-options="{  
            showClearButton: 'true',
            readOnly: !isEditing,
            height:'50'
          }"            
        />        

        <dx-simple-item
          :col-span="2"
          data-field="resolution"
          editor-type="dxTextArea"
          value: props.vehicle.resolution,
          :editor-options="{  
            showClearButton: 'true',
            readOnly: !isEditing,
            height:'50'
          }"
        />             
      </dx-group-item>
      
      <dx-group-item>
        <dx-group-item
          caption="Effort"
          name="HomeAddress"
        >
          <dx-simple-item data-field="estimatedHrs"/>
          <dx-simple-item data-field="actualHrs"/>
        </dx-group-item>
      </dx-group-item>      
    </dx-group-item> 
  </dx-form> 

  <div>
          
          <span>Date Test: </span>
          <span>{{
                  formatDate(new Date(props.vehicle.openedDtm))
                }}</span>
        </div>
</template>

<script setup>
import "devextreme/data/odata/store";
import 'whatwg-fetch';
import "devextreme-vue/text-area";
// import service from '../utils/data.service';
import { ref, defineEmits, defineProps } from 'vue';
// import { DxTextArea } from 'devextreme-vue/text-area';
// import DxDropDownBox from 'devextreme-vue/drop-down-box';
import StatusIndicator from '@/components/status-indicator.vue';
import DxSelectBox from 'devextreme-vue/select-box';
import {
  // DxValidator,
  DxRequiredRule,
} from 'devextreme-vue/validator';
// import { DxDataGrid, DxSelection, DxColumn } from 'devextreme-vue/data-grid';
import validationEngine from "devextreme/ui/validation_engine";
import {
  DxForm,
  DxSimpleItem,
  DxItem,
  DxGroupItem,
  //DxButtonItem,
  // DxTextArea,
  DxLabel,
  DxColCountByScreen
} from 'devextreme-vue/form';
import {
  DxToolbar,
  DxItem as DxToolbarItem,
} from 'devextreme-vue/toolbar';
import { DxButton } from 'devextreme-vue/button';
// import { ref, onMounted } from 'vue';
import notify from "devextreme/ui/notify";
import local from '@/stores/local';


let isEditing = ref(false);
const props = defineProps(['vehicle']);
const emit = defineEmits(['onSave']);

const dsLocalMakes = local.createDataSource('makes', 'id');
//const dsLocalSoftwares = local.createDataSource('softwares', 'id');
//const dsLocalCompanies = local.createDataSource('companies', 'id');
const dsLocalInstallTypes = local.createDataSource('installTypes', 'id');
const dsLocalRearWheelTypes = local.createDataSource('rearWheelTypes', 'id');

const getTimeAgo = (interval, unit) =>{
  if (interval > 2){
    return Math.floor(interval) + " " + unit + "s ago";
  }
  if (interval > 1){
    return Math.floor(interval) + " " + unit + " ago";
  }
}

const formatDate = (date) => {
  var seconds = Math.floor((new Date() - date) / 1000);
  var years = seconds / 31536000;
  var months = seconds / 2592000;
  var weeks = seconds / 604800;
  var days = seconds / 86400;
  var hours = seconds / 3600;
  var minutes = seconds / 60;

  if (years > 1){return getTimeAgo(years, "year")}
  if (months > 1){return getTimeAgo(months, "month")}
  if (weeks > 1){return getTimeAgo(weeks, "week")}
  if (days > 1){return getTimeAgo(days, "day")}
  if (hours > 1){return getTimeAgo(hours, "hour")}
  if (minutes > 1){return getTimeAgo(minutes, "minute")}
  return getTimeAgo(seconds, "second")
}

const formClick = () => {
  console.log("CLICKED");
  isEditing.value = !isEditing.value;
}

const validateClick = () => {
  let validationResult = validationEngine.validateGroup("formGroup");
  
  if (!validationResult.isValid){
    notify("The form is invalid.", "error");
  }
  else {
    notify("Validation successful!", "success");
    emit('onSave');
    isEditing.value = false;
  }
}

const editClick = () => {
  //let validationResult = validationEngine.validateGroup("formGroup");
  
  isEditing.value = true;

  // if (!validationResult.isValid){
  //   notify("The form is invalid.", "error");
  // }
  // else {
  //   notify("Validation successful!", "success");
  //   emit('onSave');
  // }
}

const cancelClick = () => {
  //let validationResult = validationEngine.validateGroup("formGroup");
  
  isEditing.value = false;

  // if (!validationResult.isValid){
  //   notify("The form is invalid.", "error");
  // }
  // else {
  //   notify("Validation successful!", "success");
  //   emit('onSave');
  // }
}

</script>

<style lang="scss">
/* .dx-datagrid-header-panel{
  margin-right: 100px 0;
} */
// @use "@/variables" as *;

// .view-wrapper {
//   position: absolute;
//   top: 0;
//   bottom: 0;
//   left: 0;
//   right: 0;

//   .dx-toolbar {
//     padding: $toolbar-vertical-padding $content-padding;
//   }

//   :deep(.dx-toolbar) {
//     .toolbar-header {
//       @include header();
//     }
//   }
// }

// .dx-texteditor.dx-editor-filled{
//   background-color:#ffffff;
// }

.dx-texteditor.dx-state-readonly.dx-editor-filled{
  background-color:#ffffff;
}

</style>