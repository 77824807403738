import api from '@/utils/api.service'
import CustomStore from "devextreme/data/custom_store";
import { useUserStore } from "@/stores/user";
import { jsPDF } from 'jspdf';
import { exportDataGrid } from 'devextreme/pdf_exporter';

export default {
    onExporting(e) {
        const doc = new jsPDF();
        exportDataGrid({
          jsPDFDocument: doc,
          component: e.component,
          indent: 5,
        }).then(() => {
          doc.save('Software.pdf');
        });
    },
    onSaving(e) {       
        const userStore = useUserStore();
        e.changes.forEach(x => {
            if (x.data != null) {
                Object.assign(x.data, {["enterpriseId"]: userStore.enterpriseId});
            }
        });
    },
    createDataSource(endpoint, keyfield){
        return new CustomStore({
            key: keyfield,
            load: () => {return this.get(endpoint)},
            byKey: (key) => {return this.getSingle(endpoint, key)},           
            update: (key, values) => {return this.update(endpoint, key, values)},    
            insert: (values) => {return this.insert(endpoint, values)},            
            delete: (key) => {return this.delete(endpoint, key)},   
            remove: (key) => {return this.delete(endpoint, key)},                
        });
    },

    authHeader() {
        const userStore = useUserStore();
        const isLoggedIn = !!userStore?.email;
   
        if (isLoggedIn) {
            return { Authorization: `Bearer ${userStore.token}` };
        } else {
            return {};
        }
    },
    
    buildFetchOptions(method, body){
        const requestOptions = {
            method,
            headers: this.authHeader()
        };
        if (body) {
            requestOptions.headers['Content-Type'] = 'application/json';
            requestOptions.body = JSON.stringify(body);
        }
        return requestOptions;
    },

    fetcher(url, method, body){
        return fetch(url, this.buildFetchOptions(method, body))
             .then(api.handleErrors)
             .then(response => {
                if (method != "DELETE"){
                  return response.json()
                }
             })
             //.catch(() => { throw 'Network error' }) 
    },

    get(endpoint) {
        return this.fetcher(api.apiUrl + endpoint, "GET");      
    },
    getSingle(endpoint, key) {
        return this.fetcher(api.apiUrl + endpoint +'/'+ key, "GET");
    },
    update(endpoint, key, values) {
        return this.fetcher(api.apiUrl + endpoint +'/'+ key, "PUT", values);
    },
    insert(endpoint, values) {
        return this.fetcher(api.apiUrl + endpoint, "POST", values);
    },
    delete(endpoint, key) {
        return this.fetcher(api.apiUrl + endpoint +'/'+ key, "DELETE");
    }
}            