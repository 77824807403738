import api from '@/utils/api.service'
import service from '@/utils/data.service'
import localStore from '@/stores/local'
import { useUserStore } from "@/stores/user";
import MD5 from "crypto-js/md5";

async function apiLogin(values) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json", Authorization: `Basic YXNoYXc6TWFuaWNGb2N1cy45Mg==`},
        body: JSON.stringify(values)
        };
    
        return await fetch(api.apiUrl + 'members/Login', requestOptions)
                .then(api.handleErrors)
                .then(response => response.json())
                .catch(() => { throw 'Network error' })            
}

async function apiAuthenticate(values) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" }, //, Authorization: `Basic YXNoYXc6TWFuaWNGb2N1cy45Mg==`},
        body: JSON.stringify(values)
        };
    
        return await fetch(api.apiUrl + 'token', requestOptions)
                .then(api.handleErrors)
                .then(response => response.json())
                //.catch(() => { throw 'Unable to Authenticate' })  
}        

async function apiValidateEmail(values) {
  const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" }, //, Authorization: `Basic YXNoYXc6TWFuaWNGb2N1cy45Mg==`},
      body: JSON.stringify(values)
      };
  
      return await fetch(api.apiUrl + 'members/ValidateEmail', requestOptions)
              .then(api.handleErrors)
              .then(response => response.json())
              .catch(() => { throw 'Unable to Validate Email' })  
}     

async function apiCreateAccount(values) {
  const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" }, //, Authorization: `Basic YXNoYXc6TWFuaWNGb2N1cy45Mg==`},
      body: JSON.stringify(values)
      };
  
      return await fetch(api.apiUrl + 'members', requestOptions)
              //.then(api.handleErrors)
              .then(response => response.status)
              .catch(() => { throw 'Unable to Create Account' })  
}     

async function apiCreateEnterprise(values) {
  const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" }, //, Authorization: `Basic YXNoYXc6TWFuaWNGb2N1cy45Mg==`},
      body: JSON.stringify(values)
      };
  
      return await fetch(api.apiUrl + 'enterprises', requestOptions)
              //.then(api.handleErrors)
              .then(response => response.json())
              .catch(() => { throw 'Unable to Create Enterprise' })  
} 

export default {

  isAdmin() {
    const userStore = useUserStore();
    return !!userStore.isAdmin;
  },

  isEnterprise() {
    const userStore = useUserStore();
    return !!userStore.isEnterprise;
  },

  loggedIn() {
    const userStore = useUserStore();
    return !!userStore.email;
  },

  async logIn(email, password) {
    try {
      let hashedPassword = MD5(password).toString();

      const loginResult = await apiLogin({email: email, password: hashedPassword});
      const userStore = useUserStore();
      
      userStore.user = loginResult;
      userStore.id = loginResult.id;
      userStore.enterpriseId = loginResult.enterpriseId;
      userStore.storeId = loginResult.storeId;
      userStore.username = loginResult.username;
      userStore.password = loginResult.hashedPassword;
      userStore.email = loginResult.email;
      userStore.firstname = loginResult.firstName;
      userStore.lastname = loginResult.lastName;
      userStore.phone = loginResult.phone;  

      userStore.isAdmin = loginResult.storeAdmin;
      userStore.isEnterprise = loginResult.enterpriseAdmin;  

      const parts = await service.fetcher(api.apiUrl + 'parts', "GET");
      localStore.createLocalStore('parts', 'id', parts);

      const stores = await service.fetcher(api.apiUrl + 'stores', "GET");
      localStore.createLocalStore('stores', 'id', stores);

      const vendors = await service.fetcher(api.apiUrl + 'vendors', "GET");
      localStore.createLocalStore('vendors', 'id', vendors);

      const manufacturers = await service.fetcher(api.apiUrl + 'manufacturers', "GET");
      localStore.createLocalStore('manufacturers', 'id', manufacturers);     

      const members = await service.fetcher(api.apiUrl + 'members', "GET");
      localStore.createLocalStore('members', 'id', members);     

      const makes = await service.fetcher(api.apiUrl + 'makes', "GET");
      localStore.createLocalStore('makes', 'id', makes);  

      const rearWheelTypes = await service.fetcher(api.apiUrl + 'rearWheelTypes', "GET");
      localStore.createLocalStore('rearWheelTypes', 'id', rearWheelTypes);  

      const installTypes = await service.fetcher(api.apiUrl + 'installTypes', "GET");
      localStore.createLocalStore('installTypes', 'id', installTypes);  

      return {
        isOk: true,
        data: userStore.user
      };
    }
    catch {
      return {
        isOk: false,
        message: "Login failed"
      };
    }
  },

  async authenticate(email, password) {
    try {
      let hashedPassword = MD5(password).toString();

      console.log(password);
      console.log(hashedPassword);

      const tokenResult = await apiAuthenticate({email: email, password: hashedPassword});
      const userStore = useUserStore();

      userStore.token = tokenResult.token; 
      userStore.expireDtm = tokenResult.expireDtm; 

      return {
        isOk: true,
        data: userStore.token
      };
    }
    catch {
      return {
        isOk: false,
        message: "Authentication failed"
      };
    }
  },

  async validateEmail(email) {
    try {
      const resultStatus = await apiValidateEmail({email: email});

      if (resultStatus.result === "Invalid") {
        return false;
      }
      else if (resultStatus.result === "Valid"){
        return true;
      }
      else {
        return false;
      }
    }
    catch {
      return {
        isOk: false,
        message: "Email Validation failed"
      };
    }
  },

  async logOut() {
    const userStore = useUserStore();
    userStore.user = null;
    userStore.username = null;
    userStore.firstname = null;
    userStore.lastname = null;
    userStore.phone = null;
    userStore.email = null;
  },

  async getUser() {
    try {
      // Send request
      const userStore = useUserStore();

      return {
        isOk: true,
        data: userStore.email
      };
    }
    catch {
      return {
        isOk: false
      };
    }
  },

  async resetPassword(email) {
    try {
      // Send request
      console.log(email);

      return {
        isOk: true
      };
    }
    catch {
      return {
        isOk: false,
        message: "Failed to reset password"
      };
    }
  },

  async changePassword(email, recoveryCode) {
    try {
      // Send request
      console.log(email, recoveryCode);

      return {
        isOk: true
      };
    }
    catch {
      return {
        isOk: false,
        message: "Failed to change password"
      }
    }
  },

  async createAccount(email, password) {
    try {
      const createResult = await apiCreateAccount({email:email, password: password, enterprise: true, admin: true});

      if (createResult != 201){
        return {
          isOk: false,
          message: "Failed to create account"
        };
      }
      else if (createResult == 201)
      {
        const authResult = await this.authenticate(email, password);

        if (!authResult.isOk) {         
          return {
            isOk: false,
            message: "Failed to authenticate account"
          };
        } else {
          const loginResult = await this.logIn(email, password);

          if (!loginResult.isOk) {
            return {
              isOk: false,
              message: "Failed to login account"
            };          
          } else {
            return {
              isOk: true
            };
          }
        }
      }
    }
    catch {
      return {
        isOk: false,
        message: "Failed to create account"
      };
    }
  },

  async createEnterprise(name, description, logo) {
    try {
      const createResult = await apiCreateEnterprise({name: name, description: description, logo: logo});
      const userStore = useUserStore();
      
      userStore.enterpriseId = createResult.id;

      const updateResult = await service.update('users', userStore.id, {enterpriseId: userStore.enterpriseId});

      console.log('Update User ENT ID')
      console.log(updateResult);

      const authResult = await this.authenticate(userStore.email, userStore.password);

      if (!authResult.isOk) {         
        return {
          isOk: false,
          message: "Failed to authenticate account"
        };
      } else {
        userStore.enterpriseId = createResult.enterpriseId;

        return {
          isOk: true
        };
      }
    }
    catch {
      return {
        isOk: false,
        message: "Failed to create account"
      };
    }
  }
};
