<template>

  
   <div> 
    <h3 class="content-block">Home</h3> 
    <DxTabPanel
      
      id="tabPanel"
      :defer-rendering="false"
    >
      <DxItem title="Me">
        <template #default>
          <GridPanel 
            :columns='[
              {"data-field":"storeId", "caption":"Store", "isLookup": true, "dataSource":"stores", "dataSourceId":"id", "dataSourceValue":"name"},
              {"data-field":"memberId", "filter-values":memberFilter, "caption":"Member", "isLookup": true, "dataSource":"members", "dataSourceId":"id", "dataSourceValue":"username"},
              {"data-field":"statusId", "caption":"Status", "isLookup": true, "dataSource":"statuses", "dataSourceId":"id", "dataSourceValue":"name"},
              {"data-field":"number", "width":"135"},
              {"data-field":"customerName"},
              {"data-field":"customerEmail"},
              {"data-field":"vehicleId", "caption":"Vehicle", "isLookup": true, "dataSource":"vehicles", "dataSourceId":"id", "dataSourceValue":"model"},
              {"data-field":"vehicleDescription", "width":"200"},
              {"data-field":"vinNumber"},
              {"data-field":"salesBy"},
              {"data-field":"expire_dtm", "caption":"Expire Date", "data-type":"date"},
              {"data-field":"notes"},
              {"data-field":"createdDtm", "sort-order":"desc", "caption":"Created Date", "data-type":"date"},
              {"data-field":"createdMemberId", "caption":"Created By", "isLookup": true, "dataSource":"members", "dataSourceId":"id", "dataSourceValue":"username"},
              {"data-field":"changedDtm", "caption":"Changed Date", "data-type":"date"},
              {"data-field":"changedMemberId", "caption":"Changed By", "isLookup": true, "dataSource":"members", "dataSourceId":"id", "dataSourceValue":"username"},
            ]'
            endpoint="quotes"
            header="Quotes"
            id="id"
            :isComponent="true"
          />
        </template>
      </DxItem>
      <DxItem title="Store">
        <template #default>
          <GridPanel 
            :columns='[
              {"data-field":"storeId", "filter-values":storeFilter, "caption":"Store", "isLookup": true, "dataSource":"stores", "dataSourceId":"id", "dataSourceValue":"name"},
              {"data-field":"memberId", "caption":"Member", "isLookup": true, "dataSource":"members", "dataSourceId":"id", "dataSourceValue":"username"},
              {"data-field":"statusId", "caption":"Status", "isLookup": true, "dataSource":"statuses", "dataSourceId":"id", "dataSourceValue":"name"},
              {"data-field":"number", "width":"135"},
              {"data-field":"customerName"},
              {"data-field":"customerEmail"},
              {"data-field":"vehicleId", "caption":"Vehicle", "isLookup": true, "dataSource":"vehicles", "dataSourceId":"id", "dataSourceValue":"model"},
              {"data-field":"vehicleDescription", "width":"200"},
              {"data-field":"vinNumber"},
              {"data-field":"salesBy"},
              {"data-field":"expire_dtm", "caption":"Expire Date", "data-type":"date"},
              {"data-field":"notes"},
              {"data-field":"createdDtm", "sort-order":"desc", "caption":"Created Date", "data-type":"date"},
              {"data-field":"createdMemberId", "caption":"Created By", "isLookup": true, "dataSource":"members", "dataSourceId":"id", "dataSourceValue":"username"},
              {"data-field":"changedDtm", "caption":"Changed Date", "data-type":"date"},
              {"data-field":"changedMemberId", "caption":"Changed By", "isLookup": true, "dataSource":"members", "dataSourceId":"id", "dataSourceValue":"username"},
            ]'
            endpoint="quotes"
            header="Quotes"
            id="id"
            :isComponent="true"
          />
        </template>
      </DxItem>
      <DxItem 
        title="Enterprise" 
      >
        <template #default>
          <GridPanel 
            :columns='[
              {"data-field":"storeId", "caption":"Store", "isLookup": true, "dataSource":"stores", "dataSourceId":"id", "dataSourceValue":"name"},
              {"data-field":"memberId", "caption":"Member", "isLookup": true, "dataSource":"members", "dataSourceId":"id", "dataSourceValue":"username"},
              {"data-field":"statusId", "caption":"Status", "isLookup": true, "dataSource":"statuses", "dataSourceId":"id", "dataSourceValue":"name"},
              {"data-field":"number", "width":"135"},
              {"data-field":"customerName"},
              {"data-field":"customerEmail"},
              {"data-field":"vehicleId", "caption":"Vehicle", "isLookup": true, "dataSource":"vehicles", "dataSourceId":"id", "dataSourceValue":"model"},
              {"data-field":"vehicleDescription", "width":"200"},
              {"data-field":"vinNumber"},
              {"data-field":"salesBy"},
              {"data-field":"expire_dtm", "caption":"Expire Date", "data-type":"date"},
              {"data-field":"notes"},
              {"data-field":"createdDtm", "sort-order":"desc", "caption":"Created Date", "data-type":"date"},
              {"data-field":"createdMemberId", "caption":"Created By", "isLookup": true, "dataSource":"members", "dataSourceId":"id", "dataSourceValue":"username"},
              {"data-field":"changedDtm", "caption":"Changed Date", "data-type":"date"},
              {"data-field":"changedMemberId", "caption":"Changed By", "isLookup": true, "dataSource":"members", "dataSourceId":"id", "dataSourceValue":"username"},
            ]'
            endpoint="quotes"
            header="Quotes"
            id="id"
            :isComponent="true"
          />
        </template>
      </DxItem>      
    </DxTabPanel>
    <!-- <DxTabs :data-source="longtabs" @item-click="onItemClick" class="tabs"/> -->

      <!-- <GridPanel 
        :columns='[
          {"data-field":"storeId", "caption":"Store", "isLookup": true, "dataSource":"stores", "dataSourceId":"id", "dataSourceValue":"name"},
          {"data-field":"memberId", "filter-values":memberFilter, "caption":"Member", "isLookup": true, "dataSource":"members", "dataSourceId":"id", "dataSourceValue":"username"},
          {"data-field":"statusId", "caption":"Status", "isLookup": true, "dataSource":"statuses", "dataSourceId":"id", "dataSourceValue":"name"},
          {"data-field":"number", "width":"135"},
          {"data-field":"customerName"},
          {"data-field":"customerEmail"},
          {"data-field":"vehicleId", "caption":"Vehicle", "isLookup": true, "dataSource":"vehicles", "dataSourceId":"id", "dataSourceValue":"model"},
          {"data-field":"vehicleDescription", "width":"200"},
          {"data-field":"vinNumber"},
          {"data-field":"salesBy"},
          {"data-field":"expire_dtm", "caption":"Expire Date", "data-type":"date"},
          {"data-field":"notes"},
          {"data-field":"createdDtm", "sort-order":"desc", "caption":"Created Date", "data-type":"date"},
          {"data-field":"createdMemberId", "caption":"Created By", "isLookup": true, "dataSource":"members", "dataSourceId":"id", "dataSourceValue":"username"},
          {"data-field":"changedDtm", "caption":"Changed Date", "data-type":"date"},
          {"data-field":"changedMemberId", "caption":"Changed By", "isLookup": true, "dataSource":"members", "dataSourceId":"id", "dataSourceValue":"username"},
        ]'
        endpoint="quotes"
        header="Quotes"
        id="id"
        :isComponent="true"
      /> -->
      <!-- <h3 class="content-block">Home</h3>   -->
      <!-- <DxTabs :data-source="longtabs" @item-click="onItemClick" class="tabs" item-index="1"/> -->
      <!-- </GridPanel> -->

    </div>
</template>

<script setup>
import GridPanel from "../components/grid-panel";
// import DxTabs from 'devextreme-vue/tabs';
import DxTabPanel, { DxItem } from 'devextreme-vue/tab-panel';
// import { useUserStore } from "@/stores/user";
// const longtabs = [
//   {
//     id: 0,
//     text: 'Me',
//     icon: 'user',
//     content: 'User tab content',
//   },
//   {
//     id: 1,
//     text: 'Store',
//     icon: 'comment',
//     content: 'Comment tab content',
//   },
//   {
//     id: 2,
//     text: 'Enterprise',
//     icon: 'find',
//     content: 'Find tab content',
//   },  
// ];
// const userStore = useUserStore();
    // return !!userStore.isAdmin;
const memberFilter = ["1342"];

// const storeFilter = ["1342"];

// const enterpriseFilter = ["1342"];

// function onItemClick(e) {
//             console.log(e.itemIndex);
//             console.log(e.itemData.text);
//         }
</script>

<style scoped lang="scss">
@use "@/variables" as *;

@include separator();
.view-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  .dx-toolbar {
    padding: $toolbar-vertical-padding $content-padding;
  }

  :deep(.dx-toolbar) {
    .toolbar-header {
      @include header();
    }
  }

  .grid, .kanban, .gantt {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    max-height: calc(100% - $toolbar-height - $content-padding * 2);
  }
}

.tabs{
  font-size: 16px;
  height: 50px;
}

</style>
